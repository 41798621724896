import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  confirmModalOpen: false,
  currentAdmin: false,
  error: false,
  isOpen: false,
  isOrgMember: false,
};

const organizationMemberSlice = createSlice({
  name: 'organizationMember',
  initialState,
  reducers: {
    setIsOpen(state, action) {
      state.isOpen = action.payload;
    },
    setIsOrgMember(state, action) {
      state.isOrgMember = action.payload;
    },
    setConfirmModalOpen(state, action) {
      state.confirmModalOpen = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setCurrentAdmin(state, action) {
      state.currentAdmin = action.payload;
    },
  },
});

export const OrganizationMemberActions = organizationMemberSlice.actions;

export default organizationMemberSlice;
