export const iw_sense_out_alarm_map = {
  1: { value: 'Chemical is finished', levelMessage: 'problem', level: 3 },
  2: { value: 'OFA - Over feed alarm', levelMessage: 'problem', level: 3 },
  3: { value: 'Measure communication is not present', levelMessage: 'warning', level: 2 },
  4: { value: 'Measure in calibration', levelMessage: 'running', level: 1 },
  5: { value: 'Startup delay', levelMessage: 'running', level: 1 },
  6: { value: 'Delay after calibration', levelMessage: 'running', level: 1 },
  7: { value: 'Range alarm', levelMessage: 'warning', level: 2 },
  8: { value: 'Under-dosing alarm', levelMessage: 'warning', level: 2 },
  9: { value: 'Over-dosing alarm', levelMessage: 'warning', level: 2 },
};

export const iw_sense_alarm_map = {
  1: { value: 'Chemical is finished', levelMessage: 'problem', level: 3 },
  2: { value: 'OFA - Over feed alarm', levelMessage: 'problem', level: 3 },
  3: { value: 'Lack of measure communication', levelMessage: 'warning', level: 2 },
  4: { value: 'Pump in calibration', levelMessage: 'running', level: 1 },
  5: { value: 'Startup delay', levelMessage: 'running', level: 1 },
  6: { value: 'Delay after calibration', levelMessage: 'running', level: 1 },
  7: { value: 'Range alarm', levelMessage: 'warning', level: 2 },
  8: { value: 'Under-dosing alarm', levelMessage: 'warning', level: 2 },
  9: { value: 'Over-dosing alarm', levelMessage: 'warning', level: 2 },
  10: { value: 'Lack of flow', levelMessage: 'problem', level: 3 },
  11: {},
  12: { value: 'PT100 is not present', levelMessage: 'warning', level: 2 },
  13: { value: 'Out of time slot', levelMessage: 'warning', level: 2 },
};
