import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentStep: 0,
  // User input
  amountChemicalProduct: 0,
  initialMl: '',
  initialNrOfStrokes: 0,
  isLoading: false,
  isPaused: false,
  nrOfStrokes: 0,
  pumpFlowRate: 0,
  rpmMaxSet: '',
};

const pumpCalibrationSlice = createSlice({
  name: 'pumpCalibration',
  initialState,
  reducers: {
    decreaseStep(state) {
      state.currentStep--;
    },
    increaseStep(state) {
      state.currentStep++;
    },
    resetState() {
      return { ...initialState };
    },
    setAmountOfChemicalProduct(state, action) {
      state.amountChemicalProduct = Number(action.payload);
    },
    setInitialMl(state, action) {
      state.initialMl = Number(action.payload);
    },
    setInitialNrOfStrokes(state, action) {
      state.initialNrOfStrokes = Number(action.payload);
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setIsPaused(state, action) {
      state.isPaused = action.payload;
    },
    setNrOfStrokes(state, action) {
      state.nrOfStrokes = Number(action.payload);
    },
    setPumpFlowRate(state, action) {
      state.pumpFlowRate = Number(action.payload);
    },
    setRpmMaxSet(state, action) {
      state.rpmMaxSet = action.payload;
    },
    toggleIsPaused(state) {
      state.isPaused = !state.isPaused;
    },
  },
});

export const pumpCalibrationActions = pumpCalibrationSlice.actions;

export default pumpCalibrationSlice;
