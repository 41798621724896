import { createSlice } from '@reduxjs/toolkit';

const outputSlice = createSlice({
  name: 'output',
  initialState: {
    activeMeters: [],
    activeModes: [],
    activeSetpoints: [],
    activeSwitches: [],
    allCicles: [],
    currentStep: 0,
    isLoading: false,
    outputs: {},
    relays: [],
    selectedOut: {},
    selectedOutput: null,
    selectedAlarmId: null,
  },
  reducers: {
    decreaseStep(state) {
      state.currentStep--;
    },
    increaseStep(state) {
      state.currentStep++;
    },
    resetStepState() {
      return { currentStep: 0 };
    },
    setActiveSetpoints(state, action) {
      state.activeSetpoints = action.payload;
    },
    setActiveSwitches(state, action) {
      state.activeSwitches = action.payload;
    },
    setAllCicles(state, action) {
      state.allCicles = action.payload;
    },
    setCurrentStep(state, action) {
      state.currentStep = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setOutputs(state, action) {
      state.outputs = action.payload;
    },
    setRelays(state, action) {
      state.relays = action.payload;
    },
    setSelectedAlarmId(state, action) {
      state.selectedAlarmId = action.payload;
    },
    setSelectedOutput(state, action) {
      state.selectedOutput = action.payload;
    },
    setSelectedOut(state, action) {
      state.selectedOut = action.payload;
    },
    setMultiActiveValues(state, action) {
      state.relays = action.payload.relays;
      state.activeSetpoints = action.payload.setpoints;
      state.activeMeters = action.payload.meters;
      state.activeModes = action.payload.modes;
    },
  },
});
export const outputActions = outputSlice.actions;

export default outputSlice;
