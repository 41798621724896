import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  maLowerLimit: null,
  maMaximumLimit: null,
  maMinimumLimit: null,
  maUpperLimit: null,
};

const maModeSlice = createSlice({
  name: 'maMode',
  initialState,
  reducers: {
    setMaLowerLimit(state, action) {
      state.maLowerLimit = action.payload;
    },
    setMaStates(state, action) {
      state.maLowerLimit = action.payload.min;
      state.maMaximumLimit = action.payload.maxL;
      state.maMinimumLimit = action.payload.minL;
      state.maUpperLimit = action.payload.max;
    },
    setMaUpperLimit(state, action) {
      state.maUpperLimit = action.payload;
    },
    setMaximumLimit(state, action) {
      state.maMaximumLimit = action.payload;
    },
    setMinimumLimit(state, action) {
      state.maMinimumLimit = action.payload;
    },
    resetState() {
      return { ...initialState };
    },
  },
});

export const maModeActions = maModeSlice.actions;

export default maModeSlice;
