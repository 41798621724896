import React from 'react';

import PropTypes from 'prop-types';

// Icons
import { ReactComponent as AutorenewIcon } from 'assets/icons/autorenew.svg';
import { ReactComponent as AccountCircle } from 'assets/icons/account_circle.svg';
import { ReactComponent as AddPersonIcon } from 'assets/icons/person-add.svg';
import { ReactComponent as ArrowChangeIcon } from 'assets/icons/arrow_change.svg';
import { ReactComponent as BuildIcon } from 'assets/icons/build.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as DoubleArrowRight } from 'assets/icons/double_arrow_right.svg';
import { ReactComponent as HalfDropIcon } from 'assets/icons/half-drop.svg';
import { ReactComponent as IconAbout } from 'assets/icons/about.svg';
import { ReactComponent as IconAdvanced } from 'assets/icons/advanced.svg';
import { ReactComponent as IconAlarm } from 'assets/icons/alarm.svg';
import { ReactComponent as IconAlarmAdd } from 'assets/icons/alarm_add.svg';
import { ReactComponent as IconBack } from 'assets/icons/arrow_back.svg';
import { ReactComponent as IconCircleCheck } from 'assets/icons/check_circle.svg';
import { ReactComponent as IconClock } from 'assets/icons/clock.svg';
import { ReactComponent as IconClose } from 'assets/icons/Close.svg';
import { ReactComponent as IconDelete } from 'assets/icons/delete.svg';
import { ReactComponent as IconDown } from 'assets/icons/arrow_down.svg';
import { ReactComponent as IconDrop } from 'assets/icons/water_drop.svg';
import { ReactComponent as IconEdit } from 'assets/icons/edit.svg';
import { ReactComponent as IconFactory } from 'assets/icons/factory.svg';
import { ReactComponent as IconFilter } from 'assets/icons/filter.svg';
import { ReactComponent as IconPage } from 'assets/icons/page.svg';
import { ReactComponent as IconPlus } from 'assets/icons/plus.svg';
import { ReactComponent as IconPowerOff } from 'assets/icons/power_off.svg';
import { ReactComponent as IconReset } from 'assets/icons/reset.svg';
import { ReactComponent as IconRestart } from 'assets/icons/restart.svg';
import { ReactComponent as IconShare } from 'assets/icons/share.svg';
import { ReactComponent as IconShare2 } from 'assets/icons/icons-share.svg';
import { ReactComponent as IconStar } from 'assets/icons/star.svg';
import { ReactComponent as IconUp } from 'assets/icons/arrow_up.svg';
import { ReactComponent as IconWatch } from 'assets/icons/watch.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as LampIcon } from 'assets/icons/lamp.svg';
import { ReactComponent as LaundryIcon } from 'assets/icons/laundry.svg';
import { ReactComponent as LoadingIcon } from 'assets/icons/loading.svg';
import { ReactComponent as LockIcon } from 'assets/icons/lock.svg';
import { ReactComponent as NextIcon } from 'assets/icons/chevron_right.svg';
import { ReactComponent as NotificationIcon } from 'assets/icons/notification.svg';
import { ReactComponent as ParametersIcon } from 'assets/icons/parameters.svg';
import { ReactComponent as PenIcon } from 'assets/icons/pen.svg';
import { ReactComponent as PeopleIcon } from 'assets/icons/contacts.svg';
import { ReactComponent as PlusPaperIcon } from 'assets/icons/plus_paper.svg';
import { ReactComponent as ProblemIcon } from 'assets/icons/problem.svg';
import { ReactComponent as ProductsIcon } from 'assets/icons/loyalty.svg';
import { ReactComponent as SecurityIcon } from 'assets/icons/security.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg';
import { ReactComponent as SupportIcon } from 'assets/icons/support.svg';
import { ReactComponent as SwimmingIcon } from 'assets/icons/pool.svg';
import { ReactComponent as ThumbIcon } from 'assets/icons/thumb-up.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { ReactComponent as TrashLineIcon } from 'assets/icons/trash_line.svg';
import { ReactComponent as TrembleIcon } from 'assets/icons/tremble.svg';
import { ReactComponent as VerifiedIcon } from 'assets/icons/verified.svg';
import { ReactComponent as IconVisibility } from 'assets/icons/visibility_black.svg';
import { ReactComponent as SignalIcon } from 'assets/icons/signal.svg';
import { ReactComponent as ThermometerIcon } from 'assets/icons/thermometer.svg';
import { ReactComponent as RestartClockWiseIcon } from 'assets/icons/restatart-clockwise.svg';
import { ReactComponent as WavesIcon } from 'assets/icons/waves.svg';
import { ReactComponent as PinwheelIcon } from 'assets/icons/pinwheel.svg';
import { ReactComponent as IconDisco } from 'assets/icons/disco.svg';
import { ReactComponent as IconParty } from 'assets/icons/party.svg';
import { ReactComponent as IconRainbow } from 'assets/icons/rainbow.svg';
import { ReactComponent as IconSeason } from 'assets/icons/season.svg';
import { ReactComponent as IconTorch } from 'assets/icons/torch.svg';
import { ReactComponent as PswOffIcon } from 'assets/icons/visibility_psw.svg';
import { ReactComponent as IconDone } from 'assets/icons/done.svg';
import { ReactComponent as IconBClock } from 'assets/icons/blod_clock.svg';
import { ReactComponent as IconHome } from 'assets/icons/home.svg';
import { ReactComponent as IconFavorite } from 'assets/icons/favorite.svg';
import { ReactComponent as IconAlert } from 'assets/icons/alert.svg';
import { ReactComponent as IconFilter2 } from 'assets/icons/filter2.svg';
import { ReactComponent as IconLang } from 'assets/icons/language.svg';
import { ReactComponent as WifIcon } from 'assets/icons/wifi.svg';
/**
 * Component used to display SVG icon
 * @prop {Object} customStyle - style of the icon
 * @prop {String} customClass
 * @prop {String} icon
 *  @prop {function} onClick
 */

const BaseIcon = ({ icon, customClass, customStyle, onClick }) => {
  const icons = {
    about: IconAbout,
    accountCircle: AccountCircle,
    advanced: IconAdvanced,
    add: IconPlus,
    addUser: AddPersonIcon,
    alarm: IconAlarm,
    alarmAdd: IconAlarmAdd,
    alert: IconAlert,
    aqueduct: WavesIcon,
    autorenew: AutorenewIcon,
    back: IconBack,
    boldClock: IconBClock,
    build: BuildIcon,
    calendar: CalendarIcon,
    circleCheck: IconCircleCheck,
    change: ArrowChangeIcon,
    chart: IconPlus,
    clock: IconClock,
    cross: IconClose,
    disco: IconDisco,
    delete: IconDelete,
    done: IconDone,
    doubleArrowRight: DoubleArrowRight,
    down: IconDown,
    drop: IconDrop,
    edit: IconEdit,
    factory: IconFactory,
    favorite: IconFavorite,
    filter: IconFilter,
    filter2: IconFilter2,
    home: IconHome,
    info: InfoIcon,
    language: IconLang,
    lamp: LampIcon,
    laundry: LaundryIcon,
    loading: LoadingIcon,
    lock: LockIcon,
    next: NextIcon,
    notification: NotificationIcon,
    off: IconPowerOff,
    openEye: IconVisibility,
    paper: PlusPaperIcon,
    page: IconPage,
    parameters: ParametersIcon,
    party: IconParty,
    pen: PenIcon,
    people: PeopleIcon,
    pinwheel: PinwheelIcon,
    problem: ProblemIcon,
    products: ProductsIcon,
    rainbow: IconRainbow,
    reset: IconReset,
    restart: IconRestart,
    restartClockwise: RestartClockWiseIcon,
    share: IconShare,
    share2: IconShare2,
    closeEye: PswOffIcon,
    signal: SignalIcon,
    season: IconSeason,
    security: SecurityIcon,
    settings: SettingsIcon,
    star: IconStar,
    support: SupportIcon,
    swimming: SwimmingIcon,
    thermometer: ThermometerIcon,
    thumb_up: ThumbIcon,
    torch: IconTorch,
    trash: TrashIcon,
    trash2: TrashLineIcon,
    tremble: TrembleIcon,
    up: IconUp,
    verified: VerifiedIcon,
    watch: IconWatch,
    water_treatment: HalfDropIcon,
    waves: WavesIcon,
    wifi: WifIcon,
  };

  return React.createElement(icons[icon], {
    className: customClass,
    style: customStyle,
    onClick: onClick,
  });
};

BaseIcon.propTypes = {
  customClass: PropTypes.string,
  customStyle: PropTypes.object,
  icon: PropTypes.string,
  onClick: PropTypes.func,
};

export default BaseIcon;
