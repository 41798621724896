import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  contFlowrate: null,
  discFlowRate: null,
  nrOfPulses: null,
  pulseLowerLimit: null,
  pulseMaximumLimit: null,
  pulseMinimumLimit: null,
  pulseOn: null,
  pulseOff: null,
  pulseSelectedMeasure: {},
  pulseUpperLimit: null,
};

const pulseModeSlice = createSlice({
  name: 'pulseMode',
  initialState,
  reducers: {
    setContinuousStates(state, action) {
      state.nrOfPulses = action.payload.on;
      state.contFlowrate = action.payload.rate;
    },
    setContFlowrate(state, action) {
      state.contFlowrate = action.payload;
    },
    setDiscontinuousStates(state, action) {
      state.pulseOn = action.payload.on;
      state.pulseOff = action.payload.off;
      state.discFlowRate = action.payload.rate;
    },
    setDiscFlowrate(state, action) {
      state.discFlowRate = action.payload;
    },
    setNrOfPulses(state, action) {
      state.nrOfPulses = action.payload;
    },
    setPulseLowerLimit(state, action) {
      state.pulseLowerLimit = action.payload;
    },
    setPulseSelectedMeasure(state, action) {
      state.pulseSelectedMeasure = action.payload;
    },
    setPulseOff(state, action) {
      state.pulseOff = action.payload;
    },
    setPulseOn(state, action) {
      state.pulseOn = action.payload;
    },
    setPulseStates(state, action) {
      state.pulseLowerLimit = action.payload.min;
      state.pulseMaximumLimit = action.payload.maxL;
      state.pulseMinimumLimit = action.payload.minL;
      state.pulseUpperLimit = action.payload.max;
    },
    setPulseUpperLimit(state, action) {
      state.pulseUpperLimit = action.payload;
    },
    setPulseMaximumLimit(state, action) {
      state.pulseMaximumLimit = action.payload;
    },
    setPulseMinimumLimit(state, action) {
      state.pulseMinimumLimit = action.payload;
    },
    resetContState(state) {
      state.nrOfPulses = null;
      state.contFlowrate = null;
    },
    resetDiscStates(state) {
      state.pulseOn = null;
      state.pulseOff = null;
      state.discFlowRate = null;
    },
  },
});

export const pulseModeActions = pulseModeSlice.actions;

export default pulseModeSlice;
