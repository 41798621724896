import { createSlice } from '@reduxjs/toolkit';
import { registerUser, authenticate, checkSignUpStatus } from './login-actions';

const initialState = {
  //  user object
  userData: {
    auth_provider_id: '',
    birthday: '',
    email: '',
    confirmPassword: '',
    name: '',
    newPassword: '',
    phone: '',
    password: '',
    resetPin: null,
    role: 'Admin',
    surname: '',
  },
  acceptTerms: false,
  attribute: null,
  token: localStorage.getItem('token') || null,
  authMode: 'signin',
  cognitoToken: localStorage.getItem('cognitoToken') || null,
  confirmPasswordShown: false,
  currentStep: 0,
  error: null,
  errorMsg: null,
  loading: false,
  isLoading: false,
  isValid: false,
  isReset: false,
  invitationToken: '',
  nextButtonClicked: false,
  organizationEmail: '',
  organizationName: '',
  passwordShown: false,
  showModal: false,
  successCodeSent: false,
  // stayLogged: false,
  success: false,
  twoFactorCode: null,
  unConfirmedUser: false,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    decreaseStep(state) {
      state.currentStep--;
    },
    increaseStep(state) {
      state.currentStep++;
    },
    resetState() {
      return { ...initialState };
    },

    resetUserData(state) {
      state.userData = {
        birthday: '',
        email: '',
        confirmPassword: '',
        name: '',
        newPassword: '',
        phone: '',
        password: '',
        resetPin: null,
        role: 'Admin',
        surname: '',
      };
    },
    setAcceptTerms(state, action) {
      state.acceptTerms = action.payload;
    },
    selectAttribute(state, action) {
      state.attribute = action.payload;
    },
    setAuthMode(state, action) {
      state.authMode = action.payload;
    },
    setCurrentStep(state, action) {
      state.currentStep = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setErrorMsg(state, action) {
      state.errorMsg = action.payload;
    },
    setCloseModal(state, action) {
      (state.error = action.payload.error), (state.errorMsg = action.payload.errorMsg);
      state.showModal = action.payload.showModal;
    },
    setInvitationToken(state, action) {
      state.invitationToken = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setIsReset(state, action) {
      state.isReset = action.payload;
    },
    setNextButtonClicked(state, action) {
      state.nextButtonClicked = action.payload;
    },
    setOrganizationEmail(state, action) {
      state.organizationEmail = action.payload;
    },
    setOrganizationName(state, action) {
      state.organizationName = action.payload;
    },
    setShowPassword(state, action) {
      state.passwordShown = action.payload;
    },
    setShowConfirmPassword(state, action) {
      state.confirmPasswordShown = action.payload;
    },
    setShowModal(state, action) {
      state.showModal = action.payload;
    },
    setSuccessCodeSent(state, action) {
      state.successCodeSent = action.payload;
    },
    setTwoFactorCode(state, action) {
      state.twoFactorCode = action.payload;
    },
    // setStayLogged(state, action) {
    //   state.stayLogged = action.payload;
    // },
    setUserData(state, action) {
      state.userData = action.payload;
    },
    setAuthToken(state, action) {
      state.token = action.payload;
    },
    setCognitoToken(state, action) {
      state.cognitoToken = action.payload;
    },
    setIsValid(state, action) {
      state.isValid = action.payload;
    },
    setUnConfirmedUser(state, action) {
      state.unConfirmedUser = action.payload;
    },

    setUserAndUnconfirmed(state, action) {
      (state.userData = action.payload.user), (state.unConfirmedUser = action.payload.confirmed);
    },

    login(state, action) {
      state.userData = action.payload.userData;
      state.isLoggedIn = true;
    },
    setStepAndConfirmUser(state, action) {
      state.currentStep = action.payload.step;
      state.unConfirmedUser = action.payload.unconfirmed;
      state.userData.email = action.payload.email;
      state.userData.auth_provider_id = action.payload.auth_provider_id;
      state.invitationToken = action.payload.invitationToken;
    },
    resertStateSigup(state, action) {
      state.authMode = action.payload;
      state.showModal = false;
      state.error = null;
      state.errorMsg = null;
    },
    resetErrorAndModalError(state) {
      state.error = null;
      state.showModal = false;
      state.errorMsg = null;
      state.successCodeSent = false;
    },
    setErrorIntoStatus(state, action) {
      state.errorMsg = action.payload;
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    // register user
    builder
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true; // registration successful
        state.error = payload;
      })
      .addCase(registerUser.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      // LOGIN
      .addCase(authenticate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(authenticate.fulfilled, (state) => {
        state.loading = false;
        state.success = true; // login successful
      })
      .addCase(authenticate.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      // CHECK SIGN UP STATUS
      .addCase(checkSignUpStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(checkSignUpStatus.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(checkSignUpStatus.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const LoginActions = loginSlice.actions;

export default loginSlice;
