import { Trans } from 'react-i18next';

/**
 * Hook used to generate a markup with the translated paragraphs
 * @param {Number} nrOfElements - number of paragraphs that should be generated
 * @param {String} content - translation location
 * @param {String} className - additional class name
 * @param {Object} values - values to pass for dynamic translations (e.g., { measure: 'some value' })
 */
export const useParagraphsMarkup = (nrOfElements, content, className, values = {}) => {
  const elements = Array(nrOfElements).fill(0);

  const paragraphsMarkup = () => {
    return (
      <Trans i18nKey={content} values={values}>
        {elements.map((el, index) => (
          <p className={`${'paragraph'} ${className || ''} `} key={index}></p>
        ))}
      </Trans>
    );
  };

  return paragraphsMarkup();
};
