import { createSlice } from '@reduxjs/toolkit';

const appSlice = createSlice({
  name: 'app',
  initialState: {
    confirmOpenModal: false,
    deviceNotRespond: false,
    errorModalOpen: false,
    errorType: '',
    isMacConfiguration: false,
    isLoading: false,
    isOpen: false,
    noHeartbeat: false,
    navigationDisplayed: true,
    openModal: false,
    platform: 'web',
    selectedParam: {},
  },
  reducers: {
    hideNavigation(state) {
      state.navigationDisplayed = false;
    },
    showNavigation(state) {
      state.navigationDisplayed = true;
    },

    setConfirmModalOpen(state, action) {
      state.confirmOpenModal = action.payload;
    },
    setDeviceAndMacStatus(state, action) {
      state.isMacConfiguration = action.payload.mac;
      state.deviceNotRespond = action.payload.device;
    },
    setDeviceNotRespond(state, action) {
      state.deviceNotRespond = action.payload;
    },
    setErrorAndErrorType(state, action) {
      state.errorType = action.payload.errorType;
      state.errorModalOpen = action.payload.isOpen;
      state.deviceNotRespond = action.payload.device;
    },
    setErrorModalOpen(state, action) {
      state.errorModalOpen = action.payload;
    },
    setIsMacConfiguration(state, action) {
      state.isMacConfiguration = action.payload;
    },
    setIsOpen(state, action) {
      state.isOpen = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setOpenModal(state, action) {
      state.openModal = action.payload;
    },
    setPlatform(state, action) {
      state.platform = action.payload;
    },
    setSelectedParam(state, action) {
      state.selectedParam = action.payload;
    },
    setNoHeartbeat(state, action) {
      state.noHeartbeat = action.payload;
    },
  },
});

export const appActions = appSlice.actions;

export default appSlice;
