import { createSlice } from '@reduxjs/toolkit';

const paramChartSlice = createSlice({
  name: 'paramChart',
  initialState: {
    email: '',
    endDate: '',
    isError: false,
    isModalOpen: false,
    openFilter: false,
    paramsData: [],
    shareFilter: false,
    selectedIndex: 0,
    selectedTime: 'today',
    startDate: '',
    time: 'today',
  },
  reducers: {
    setEmail(state, action) {
      state.email = action.payload;
    },
    setEndDate(state, action) {
      state.endDate = action.payload ? new Date(action.payload).toISOString() : null;
    },
    setIsError(state, action) {
      state.isError = action.payload;
    },
    setIsModalOpen(state, action) {
      state.isModalOpen = action.payload;
    },
    setShareFilter(state, action) {
      state.shareFilter = action.payload;
    },
    setOpenFilter(state, action) {
      state.openFilter = action.payload;
    },
    setSelectedIndex(state, action) {
      state.selectedIndex = action.payload;
    },
    setSelectedTimeFilter: (state, action) => {
      state.selectedTime = action.payload.time;
      state.openFilter = action.payload.openFilter;
    },
    setStartDate(state, action) {
      state.startDate = action.payload ? new Date(action.payload).toISOString() : null;
    },
    setSelectedTimeIndex(state, action) {
      state.time = action.payload.time;
      state.selectedIndex = action.payload.index;
    },
    setParamsData: (state, action) => {
      state.paramsData = action.payload;
    },
    resetFilterState: (state) => {
      state.selectedTime = 'today';
      state.time = 'today';
      state.selectedIndex = 0;
      state.startDate = '';
      state.endDate = '';
      state.openFilter = false;
    },
    resetShareState: (state) => {
      state.shareFilter = false;
      state.endDate = '';
      state.startDate = '';
      state.email = '';
    },
    retry(state, action) {
      state.isError = action.payload.error;
      state.shareFilter = action.payload.filter;
    },
  },
});
export const paramChartActions = paramChartSlice.actions;

export default paramChartSlice;
