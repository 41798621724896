import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isModalOpen: false,
  radioButtonValue: null,
};

const selectNewOwnerSlice = createSlice({
  name: 'newOwner',
  initialState,
  reducers: {
    setRadioButtonValue(state, action) {
      state.radioButtonValue = action.payload;
    },
    setIsModalOpen(state, action) {
      state.isModalOpen = action.payload;
    },
  },
});

export const selectNewOwnerActions = selectNewOwnerSlice.actions;

export default selectNewOwnerSlice;
