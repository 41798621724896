// import { getNotifications } from 'store/notifications/notification-actions';
import instance from '../index';

const NOTIFICATIONS = 'notifications';
const READ = 'read';
const SUBSCRIBE = 'subcription';
const UNSUBSCRIBE = 'unsubcribe';
const TOKEN = 'token';
const USER = 'user';

const NotificationsApi = {
  /**
   * Api to delete single notification
   * @param {id}
   */
  deleteNotification(id) {
    return instance.delete(`/${NOTIFICATIONS}/${id}`);
  },
  /**
   * Api to get notification list
   * @return {Array}
   */
  getAllNotifications() {
    return instance.get(`/${NOTIFICATIONS}`);
  },
  /**
   * Api to get single notification
   * @param {id}
   * @return {Object}
   */
  getNotification(id) {
    return instance.get(`/${NOTIFICATIONS}/${id}`);
  },
  /**
   * Api to set status read on single notification
   * @param {id}
   * @return {Object}
   */
  readNotification(id) {
    return instance.post(`/${NOTIFICATIONS}/${id}/${READ}`);
  },
  /**
   * Api to save the mobile device token sended from android / ios to receive push notifications
   */
  sendDeviceToken(token, app) {
    return instance.post(`/${NOTIFICATIONS}/${SUBSCRIBE}`, { token: token, app: app });
  },
  /**
   * Api to remove the mobile device token sended from android / ios to receive push notifications
   */
  unSubscribeToken(token) {
    return instance.post(`/${NOTIFICATIONS}/${UNSUBSCRIBE}-${TOKEN}`, { token: token });
  },
  /**
   * Api to unsubribe the user for push notifications
   */
  unSubscribeUser(userId) {
    return instance.post(`/${NOTIFICATIONS}/${UNSUBSCRIBE}-${USER}`, { userId: userId });
  },
};

export default NotificationsApi;
