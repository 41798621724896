// Maps
import { iw_dose_alarm_map } from '../maps/alarm/iw-dose';
import { iw_pool_alarm_map } from 'maps/alarm/iw-pool';
import { iw_motor_pump_alarm_map } from 'maps/alarm/iw-motorPump';
import { models } from '../maps/deviceModels';

// Utils
import { isMultiPool } from './multidevice';
import { checkIsSense } from 'components/devices/IwSense/utils/senseDevices';
import { iw_sense_alarm_map, iw_sense_out_alarm_map } from 'maps/alarm/iw-sense';

const alarmMap = {
  DOSE: { alarmMessages: iw_dose_alarm_map },
  POOL: { alarmMessages: iw_pool_alarm_map },
  MULTIPOOL: { alarmMessages: iw_pool_alarm_map },
  SENSE: { alarmMessages: iw_sense_out_alarm_map },
  ULTRAPUMP: { alarmMessages: iw_motor_pump_alarm_map },
};

const STANDBY_STATUS = 'paused';
const NO_STATUS = 'none';
const PROBLEM_STATUS = 'problem';
const RUNNING_STATUS = 'running';

const getAlarmMessages = (modelFamily) => {
  return alarmMap[modelFamily]?.alarmMessages || [];
};

const getMostImportantMessage = (alarmMessages, alarm) => {
  let mostImportantMessage = { level: 0 };
  for (let i = 0; i < alarm?.length; i++) {
    if (alarm[i] === '1' || alarm[i] === '2') {
      const message = alarm[i] === '2' ? alarmMessages[i + 9] : alarmMessages[i + 1];
      if (message?.level > mostImportantMessage?.level) {
        mostImportantMessage = message;
      }
    }
  }
  return mostImportantMessage;
};
/**
 * Method used to get the device status based on the configuration and status data
 * @param {Object} config
 * @param {Object} status
 * @returns {String} status running/warning/paused
 */

export const getDeviceStatus = (config, status, model, heartBeat, elements) => {
  if (!status) {
    return NO_STATUS;
  }
  if (!heartBeat) {
    return PROBLEM_STATUS;
  }
  if (config.STANDBY === '1') {
    return STANDBY_STATUS;
  }

  const modelFamily = isMultiPool(model) ? 'MULTIPOOL' : models[model]?.modelFamily;
  if (!modelFamily) {
    return RUNNING_STATUS;
  }

  const alarmMessages = getAlarmMessages(modelFamily);
  let alarms = [];

  if (checkIsSense(model) && elements) {
    elements.forEach((element) => {
      for (let i = 0; i < element.elementsCount; i++) {
        alarms.push(
          status[
            element.type === 'mA'
              ? `ALARMmA${i}`
              : element.type === 'aux'
              ? `ALARM${i + 5}`
              : `ALARM${i}`
          ],
        );
      }
    });
  } else if (checkIsSense(model) && !elements) {
    alarms.push(status);
  } else alarms.push(status.ALARM);

  // Filter alarms to remove unfined or null values
  alarms = alarms.filter((alarm) => alarm !== undefined && alarm !== null);

  // Initialize the most important message with a low level
  let mostImportantMessage = { level: 0 };

  // Iterate over all alarms to find the most important message
  for (const alarm of alarms) {
    let newAlarmMessages;

    if (checkIsSense(model)) newAlarmMessages = iw_sense_alarm_map;
    else newAlarmMessages = alarmMessages;

    const message = getMostImportantMessage(newAlarmMessages, alarm);
    if (message?.level > mostImportantMessage?.level) {
      mostImportantMessage = message;
    }
  }
  if (mostImportantMessage?.level !== 0) {
    return mostImportantMessage.value;
  }

  return RUNNING_STATUS;
};
/**
 * Method used to get the device status based on the configuration and status data
 * @param {Object} config
 * @param {Object} status
 * @returns {String} status running/warning/paused
 */

export const detectDeviceStatus = (status) => {
  switch (status) {
    case 'paused':
      return 'paused';
    case 'OFA - Over feed alarm':
    case 'Chemical is finished':
    case 'Non compliant supply voltage':
    case 'Lack of flow':
    case 'problem':
      return 'danger';
    case 'Out of time slot':
    case 'Low level':
    case 'Range alarm':
    case 'Under-dosing alarm':
    case 'Over-dosing alarm':
    case 'PTC intervention (temperature probe)':
    case 'Lack of measure communication':
    case 'Overflow 1xN':
    case 'Measure at minimum':
    case 'Measure at maximum':
    case 'Overflow ppm':
      return 'pending';
    case 'running':
    case 'Inhibit waiting':
    case 'Pump in calibration':
    case 'Startup delay':
    case 'Out of programmed time slot':
    case 'Lack of PT100':
    case 'Delay after calibration ':
    case 'Controller is in stand-by':
      return 'running';
    case 'warning':
    case 'none':
      return 'none';
  }
};
