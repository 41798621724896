export const routes = {
  about: 'about',
  accountInfo: 'account-info',
  additionalPumpConfig: 'additional-pump-configuration',
  additionalPumpSettings: 'additional-pump-settings',
  adminHome: 'admin-home',
  addDevice: 'add-device',
  advancedSettings: 'advanced-settings',
  advProportionalMode: 'advanced-proportional-mode',
  alarmSetting: 'alarm-settings',
  automaticUpdate: 'automatic-update',
  aux: 'aux',
  buzz: 'buzzer',
  chlorinatorSettings: 'chlorinator-settings',
  chlorinatorConfig: 'chlorinator-configuration',
  connectOrganization: 'connect-organization',
  device: 'device',
  deviceConfiguration: 'device-configuration',
  deviceConfigurationChannel: 'device-configuration-channel',
  deviceSettingSelection: 'select-setting',
  deviceSelection: 'device-selection',
  externalSignal: 'external-signals',
  editPlant: 'edit-plant',
  factoryData: 'factory-data',
  first_setup: 'first-setup',
  forgotPassword: '/forgot-password',
  home: '/',
  language: 'language',
  levelAlarm: 'level-alarm',
  levelSensors: 'level-sensor',
  login: '/login',
  mA: 'mA',
  minMax: 'min-max-value',
  members: 'members',
  multiDevice: 'multi-device',
  multi_configuration: 'multi-configuration',
  multiPumpsConf: 'multi-pumps-configuration',
  multiPumpsStartup: 'multi-pumps-startup',
  newOrganizzation: 'new-organizzation',
  newPlant: '/new-plant',
  notifications: '/notifications',
  notificationsSettings: 'notifications-settings',
  ofa: 'over-feed-alarm',
  out: 'out',
  outputConfiguration: 'output-configuration',
  parameters: 'parameters-trend',
  parametersChart: 'parameters-chart',
  passwordAndSecurity: 'password-and-security',
  people: '/people',
  peopleAccount: 'people-account',
  plants: '/plants',
  pressureCalibration: 'pressure-calibration',
  probeCalibration: 'probe-calibration',
  process: 'process',
  profileMember: 'profile-member',
  pumpCalibration: 'pump-calibration',
  pumpPriming: 'pump-priming',
  pumpSetup: 'pump-setup',
  resetAndReboot: 'reset-and-reboot',
  reboot: 'reboot',
  rotationPercentage: 'motor-rotation-percentage',
  salinityCalibration: 'salinity-calibration',
  settings: 'settings',
  selectNewOwner: 'select-new-owner',
  selectPlantMobileApp: 'select-plant-mobile-app',
  selectionSensors: 'select-sensors',
  serialPort: 'serial-port',
  setup: 'setup',
  signup: '/sign-up',
  softwareUpdate: 'software-update',
  standby: 'standby',
  startup: 'startup',
  temperature: 'compensation-temperature',
};
