import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cellSize: null,
  chlorinatorMeasure: 0,
  currentStep: 0,
  disableMessage: '',
  disabledSensorsStep: false,
  firstSetup: true,
  isLoading: false,
  inhibitSensor: false,
  inputError: false,
  modalOpen: false,
  modalTitle: '',
  operatingMode: null,
  poolSize: 0,
  salinity: 0,
};

const chlorinatorSlice = createSlice({
  name: 'chlorinator',
  initialState,
  reducers: {
    decreaseStep(state) {
      state.currentStep--;
    },
    increaseStep(state) {
      state.currentStep++;
    },
    resetState() {
      return { ...initialState };
    },
    resetStepState() {
      return { currentStep: 0 };
    },
    setCellSize(state, action) {
      state.cellSize = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setOperatingMode(state, action) {
      state.operatingMode = action.payload;
    },
    setPoolSize(state, action) {
      state.poolSize = action.payload;
    },
    setSalinity(state, action) {
      state.salinity = action.payload;
    },
    setDisabledSensorsStep(state, action) {
      state.disabledSensorsStep = action.payload;
    },
    setDisableMessage(state, action) {
      state.disableMessage = action.payload;
    },
    setInputError(state, action) {
      state.inputError = action.payload;
    },
    setChlorinatorMeasure(state) {
      state.chlorinatorMeasure = state.chlorinatorMeasure === 1 ? 0 : 1;
    },
    setInhibitSensor(state, action) {
      state.inhibitSensor = action.payload;
    },
    setFistSetup(state, action) {
      state.isFirstSetup = action.payload;
    },
    setModalTitle(state, action) {
      state.modalTitle = action.payload;
    },
    setModalOpen(state) {
      state.modalOpen = !state.modalOpen;
    },
    setPrefillForFirstSetup(state, action) {
      state.chlorinatorMeasure = action.payload.measure;
      state.poolSize = action.payload.poolSize;
      state.salinity = action.payload.salinity;
      state.cellSize = action.payload.cellSize;
      state.operatingMode = action.payload.mode;
    },
  },
});

export const chlorinatorActions = chlorinatorSlice.actions;

export default chlorinatorSlice;
