import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentStep: 0,
  // User input
  applicationField: '1',
  connectionEnabled: false,
  connectionMode: 0,
  flowRateIndicatorEnabled: false,
  flowSensorEnabled: false,
  flowSensorMode: 0,
  hideFirstStep: false,
  hideFirstStepChecked: false,
  input: '',
  isLoading: false,
  levelInputPolarity: 1,
  measurementUnit: 0,
  performanceMode: 'B',
  ppmRange: null,
  proximitySensorEnabled: false,
  selectedFlowRate: null,
  selectedSensors: [],
  selectedValue: null,
  sensorType: null,
  temperatureRadioValue: undefined,
  temperatureSensorEnabled: false,
  temperatureStepSkipped: false,
  typeOfDevice: null,
};

const startUpSlice = createSlice({
  name: 'startUp',
  initialState,
  reducers: {
    addSensor(state, action) {
      if (!state.selectedSensors.includes(action.payload)) {
        state.selectedSensors = [...state.selectedSensors, action.payload];
      }
    },
    changeStep(state, action) {
      state.currentStep = action.payload;
    },
    decreaseStep(state) {
      state.currentStep = state.currentStep - 1;
    },
    increaseStep(state) {
      state.currentStep = state.currentStep + 1;
    },
    resetState() {
      return { ...initialState };
    },
    revertConnectionMode(state) {
      state.connectionMode = state.connectionMode == 1 ? 0 : 1;
    },
    reverseFlowSensorMode(state) {
      state.flowSensorMode = state.flowSensorMode === 1 ? 0 : 1;
    },
    revertLevelInputPolarity(state) {
      state.levelInputPolarity = state.levelInputPolarity === 1 ? 0 : 1;
    },
    revertMeasurementUnit(state) {
      state.measurementUnit = state.measurementUnit === 1 ? 0 : 1;
    },
    selectSensors(state, action) {
      if (state.selectedSensors.includes(action.payload)) {
        state.selectedSensors = state.selectedSensors.filter(
          (_sensor) => _sensor !== action.payload,
        );
      } else {
        state.selectedSensors = [...state.selectedSensors, action.payload];
      }
    },
    selectSensorType(state, action) {
      state.sensorType = action.payload;
    },
    selectTypeOfDevice(state, action) {
      state.typeOfDevice = action.payload;
    },
    selectValue(state, action) {
      state.selectedValue = action.payload;
    },
    setApplicationField(state, action) {
      state.applicationField = action.payload;
    },
    setConnectionEnabled(state, action) {
      state.connectionEnabled = action.payload;
    },
    setConnectionStates(state, action) {
      state.connectionEnabled = action.payload.connection;
      state.isLoading = action.payload.loading;
    },
    setFlowRateIndicatorEnabled(state, action) {
      state.flowRateIndicatorEnabled = action.payload;
    },
    setFlowSensorMode(state, action) {
      state.flowSensorMode = Number(action.payload);
    },
    setFlowSensorEnabled(state, action) {
      state.flowSensorEnabled = action.payload;
    },
    setHideFirstStep(state, action) {
      state.hideFirstStep = action.payload;
    },
    setInput(state, action) {
      state.input = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setIsProximitySensorEnabled(state, action) {
      state.proximitySensorEnabled = action.payload;
    },
    setLevelInputPolarity(state, action) {
      state.levelInputPolarity = Number(action.payload);
    },
    setPerformanceMode(state, action) {
      state.performanceMode = action.payload;
    },
    setPpmRange(state, action) {
      state.ppmRange = action.payload;
    },
    setSelectedFlowRate(state, action) {
      state.selectedFlowRate = action.payload;
    },
    setTemperatureRadioValue(state, action) {
      state.temperatureRadioValue = action.payload;
    },
    setTemperatureSensorEnabled(state, action) {
      state.temperatureSensorEnabled = action.payload;
    },
    setTemperatureStepSkipped(state, action) {
      state.temperatureStepSkipped = action.payload;
    },
    toggleConnection(state) {
      state.connectionEnabled = !state.connectionEnabled;
    },
    setConnectionMode(state, action) {
      state.connectionMode = action.payload;
    },
    toggleFirstStepIsChecked(state) {
      state.hideFirstStepChecked = !state.hideFirstStepChecked;
    },
    toggleFlowRateIndicator(state) {
      state.flowRateIndicatorEnabled = !state.flowRateIndicatorEnabled;
    },
    toggleFlowSensorEnabled(state) {
      state.flowSensorEnabled = !state.flowSensorEnabled;
    },
    toggleProximitySensorEnabled(state) {
      state.proximitySensorEnabled = !state.proximitySensorEnabled;
    },
    toggleTemperatureSensor(state) {
      state.temperatureSensorEnabled = !state.temperatureSensorEnabled;
    },
  },
});

export const startupActions = startUpSlice.actions;

export default startUpSlice;
