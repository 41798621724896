import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filteredMembers: [],
  isLoading: true,
  isModalOpen: false,
  isSlideUpOpen: false,
  newMemberEmail: '',
  roleSwap: false,
  validEmail: false,
};

const membersSlice = createSlice({
  name: 'members',
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setIsModalOpen(state, action) {
      state.isModalOpen = action.payload;
    },
    setIsSlideUpOpen(state, action) {
      state.isSlideUpOpen = action.payload;
    },
    setNewMemberEmail(state, action) {
      state.newMemberEmail = action.payload;
    },
    setFilteredMembers(state, action) {
      state.filteredMembers = action.payload;
    },
    setRoleSwap(state, action) {
      state.roleSwap = action.payload;
    },
    setValidEmail(state, action) {
      state.validEmail = action.payload;
    },
  },
});

export const MembersActions = membersSlice.actions;

export default membersSlice;
