import { getDeviceStatus } from './status';

import { iw_pool_alarm_map } from 'maps/alarm/iw-pool';
import { iw_dose_alarm_map } from 'maps/alarm/iw-dose';
// Method used to check if a given model meets the condition for being a multi-device
export const checkIsMulti = (model) => {
  return model.startsWith('IWM');
};
/**
 * Checks if the provided ID contains the letter 'M', indicating it is a multi-parent device.
 * @param {string} id - The ID to check.
 * @returns {boolean} True if the ID is multi-parent, otherwise false.
 */
export const isMultiParent = (id) => {
  return id.toString().includes('M');
};
// Check type of multi-device
export const isMultiPool = (model) => {
  return model.startsWith('IWM') && model[4] === 'P';
};

export const isMultiDose = (model) => {
  return model.startsWith('IWM') && model[4] === 'D';
};
/*
 * Method used to return the Multipool name and pump number
 */
export const multipoolPumpNameMapping = (device) => `${device.name} Pump(${device.position})`;

/**
 * Calculate the status of a multi-device based on its sub-devices' statuses.
 * @param {Object} multi - The multi-device object containing sub-devices.
 * @returns {string|number} - The calculated status or the highest alarm level.
 */
export const multiStatus = (multi) => {
  // Check if the multi-device is of type multipool or multidose
  const multipool = isMultiPool(multi.model);
  const multidose = isMultiDose(multi.model);

  // Get an array of statuses for each sub-device
  const deviceStatusArray =
    multi.devices?.map((_device) =>
      getDeviceStatus(
        _device.configuration,
        typeof _device.status !== 'string' ? _device.status : _device.statusObject,
        _device.model,
        _device.is_alive ? _device.is_alive : _device.heartBeat,
      ),
    ) || [];

  // Check if all sub-devices are paused or running
  const isAllPaused = deviceStatusArray.every((alarmString) => alarmString === 'paused');
  const isAllRunning = deviceStatusArray.every((alarmString) => alarmString === 'running');
  const isOnePaused = deviceStatusArray.includes('paused');
  const isOneProblem = deviceStatusArray.includes('problem');

  let highestLevel = 0;
  let highestState;

  // If all sub-devices are paused, return 'paused'
  if (isAllPaused || isOnePaused) {
    return (highestState = 'paused');

    // If all sub-devices are running, return 'running'
  }
  if (isAllRunning) {
    return (highestState = 'running');
    // If at least one sub-device is running and there's no errors return 'paused'
  }
  // If one of  all sub-devices are in problem, return 'problem'
  if (isOneProblem) {
    return (highestState = 'problem');
  } else {
    highestLevel = 0;
    // Iterate through the status strings in the array
    deviceStatusArray.forEach((alarmString) => {
      // Check if the string is 'running' or 'paused' and set highestLevel to 0
      if (alarmString !== 'running') {
        // Find the corresponding entry in iw_pool_alarm_map or iw_dose_alarm_map
        let alarmEntry;
        if (multipool)
          alarmEntry = Object.values(iw_pool_alarm_map).find(
            (entry) => entry.value === alarmString,
          );
        if (multidose)
          alarmEntry = Object.values(iw_dose_alarm_map).find(
            (entry) => entry.value === alarmString,
          );

        if (alarmEntry && alarmEntry.level > highestLevel) {
          // Update the highestLevel and value if necessary
          highestLevel = alarmEntry.level;
          highestState = alarmEntry.value;
        }
      }
    });
    // Now highestState will hold the highest alarm level among the matching values
    return highestState;
  }
};

/**
 * Function to find an element with a specific ID in a subarray of objects within a main array.
 * @param {Array} mainArray - The main array containing objects.
 * @param {number} itemId - The ID of the element to be found.
 * @returns {Object|null} - The found element or null if not found.
 */
export const findElementInSubarray = (mainArray, itemId) => {
  for (const item of mainArray) {
    const foundElement = item.devices?.find((subItem) => subItem.id === itemId);
    if (foundElement) {
      return foundElement;
    }
  }
  // Return null if the element is not found
  return null;
};

/**
 * This function determines the current device position based on the provided data, it returns default 2.
 * @returns {number} The current device position.
 */
export const getCurrentDevicePosition = (firstPump, secondPump, device, plant) => {
  const { thirdSubdevice } = checkSubDevice(device, plant);
  if (firstPump) return 2;
  if (secondPump) return thirdSubdevice ? 3 : null;
};

/**
 *  Method used to check if is last sub device of a multi
 * @param {*} model
 * @param {*} position
 * @returns
 */
export const isLastSubDevice = (model, position) => {
  return model.split('')[3] == position;
};

export const checkSubDevice = (device, plant) => {
  // Transform the current device id to remove any 'M' prefix (if present).
  const transformedId = isMultiParent(device.id) ? device.id.slice(1) : device.id;

  // Find the current multi-device that contains the transformed id.
  const currentMultiDevice = plant.devices.find((device) =>
    device.devices?.some((subDev) => subDev.id === Number(transformedId)),
  );

  // Find the second sub-device within the current multi-device.
  // Find the third sub-device within the current multi-device.
  const secondSubDevice = currentMultiDevice.devices.find((subDevice) => subDevice.position == 2);
  const thirdSubDevice = currentMultiDevice.devices.find((subDevice) => subDevice.position == 3);

  // Return the second and third sub-devices as an object.
  return { thirdSubDevice, secondSubDevice };
};
