import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  counterPulsesLiters: 0,
  counterModeValue: 0,
  disabled: false,
  dosageTime: 0,
  flowrate: 0,
  maxFlowRateError: false,
  ppmPercentage: 0,
  ppmValue: 0,
  radioValue: '',
  reverseValue: 0,
  seconds: 0,
  selectedOption: false,
  selectProportionalValue: {},
  upperLimitError: false,
};

const operativeModeMpSlice = createSlice({
  name: 'operativeModesMp',
  initialState,
  reducers: {
    setBatchStates(state, action) {
      state.reverseValue = action.payload.polarity || 0;
      state.flowrate = action.payload.rate;
      state.dosageTime = action.payload.time;
      state.seconds = action.payload.seconds;
      state.radioValue = action.payload.type;
    },
    setBatchTime(state, action) {
      state.dosageTime = action.payload.time;
      state.seconds = action.payload.seconds;
    },
    setCounterPulsesLiters(state, action) {
      state.counterPulsesLiters = action.payload;
    },
    setCounterMode(state) {
      state.counterModeValue = state.counterModeValue === 1 ? 0 : 1;
    },
    setDisabled(state, action) {
      state.disabled = action.payload;
    },
    setDosageTime(state, action) {
      state.dosageTime = action.payload;
    },
    setFlowrate(state, action) {
      state.flowrate = action.payload;
    },
    setMaxFlowRateError(state, action) {
      state.maxFlowRateError = action.payload;
    },
    setPpmPercentage(state, action) {
      state.ppmPercentage = action.payload;
    },
    setPpmValue(state, action) {
      state.ppmValue = action.payload;
    },
    setPpmStates(state, action) {
      state.selectProportionalValue = action.payload.proportional;
      state.counterModeValue = action.payload.counter;
      state.ppmValue = action.payload.ppmReach;
      state.ppmPercentage = action.payload.percentage;
      state.counterPulsesLiters = action.payload.counterPulses;
    },
    setRadioValue(state, action) {
      state.radioValue = action.payload;
    },
    setSeconds(state, action) {
      state.seconds = action.payload;
    },
    setSelectedOption(state, action) {
      state.selectedOption = action.payload;
    },
    setSelectProportionalValue(state, action) {
      state.selectProportionalValue = action.payload;
    },
    setToggleValue(state) {
      state.reverseValue = state.reverseValue === 1 ? 0 : 1;
    },
    setUpperLimitError(state, action) {
      state.upperLimitError = action.payload;
    },
    resetState() {
      return { ...initialState };
    },
  },
});

export const operativeModeMpActions = operativeModeMpSlice.actions;

export default operativeModeMpSlice;
