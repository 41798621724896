import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Capacitor
import { PushNotifications } from '@capacitor/push-notifications';
import { LocalNotifications } from '@capacitor/local-notifications';
import { Capacitor } from '@capacitor/core';

// Store
import { routes } from 'util/routes';
import { notificationActions } from 'store/notifications/notification-slice';
import NotificationsApi from 'api/modules/notifications';

export const usePushNotifications = async () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (Capacitor.getPlatform() === 'ios') {
      PushNotifications.checkPermissions().then((result) => {
        if (result.receive === 'granted') {
          // permission granted to receive push notification on IOS
          PushNotifications.register();
        } else {
          // permission not granted, request user permission
          PushNotifications.requestPermissions().then((result) => {
            console.log('permission request', result);
            if (result.receive === 'granted') {
              PushNotifications.register();
            }
          });
        }
      });
    } else {
      // registration for Android
      PushNotifications.requestPermissions().then((result) => {
        if (result.receive === 'granted') {
          PushNotifications.register();
        }
      });
    }

    // listener called if app is in background or kill
    PushNotifications.addListener('pushNotificationActionPerformed', async (notification) => {
      const payload = notification.notification.data;
      if (notification.actionId === 'tap') {
        handleActionPerformed(payload);
      }
    });

    PushNotifications.addListener('registration', async (token) => {
      console.log('token', token.value);
      await dispatch(notificationActions.setDeviceToken(token.value));
    });

    PushNotifications.addListener('registrationError', (error) => {
      console.log('Error on registration: ' + JSON.stringify(error));
    });

    // listener called when push notification is received
    PushNotifications.addListener('pushNotificationReceived', async (notification) => {
      if (Capacitor.getPlatform('android')) {
        LocalNotifications.createChannel({
          description: 'General Notifications',
          id: 'fcm_default_channel',
          importance: 5,
          lights: true,
          name: 'My notification channel',
          sound: '',
          vibration: true,
          visibility: 1,
        })
          .then(async () => {
            scheduleLocalNotification(notification);
            await PushNotifications.getDeliveredNotifications().then(async (delivered) => {
              await PushNotifications.removeDeliveredNotifications(delivered);
            });
          })
          .catch((error) => {
            console.error('push channel error: ', error);
          });
      }
      let tempNotification = { ...notification.data, title: notification.title };
      dispatch(notificationActions.addNotification(tempNotification));
    });

    // listener called if app is open due to a problem in capacitor
    LocalNotifications.addListener(
      'localNotificationActionPerformed',
      async (localNotification) => {
        const payload = localNotification.notification.extra;
        if (localNotification.actionId === 'tap') {
          handleActionPerformed(payload);
        }
      },
    );
  }, []);

  // metoh to convert push notification intto local notificartion when app is open 'cause a capacitor bug
  const scheduleLocalNotification = async (notification) => {
    LocalNotifications.schedule({
      notifications: [
        {
          id: Math.floor(Math.random() * 10000),
          title: notification.title,
          schedule: { at: new Date(Date.now() + 1000 * 1) },
          extra: notification.data,
          channelId: 'fcm_default_channel',
        },
      ],
    });
  };

  // called after user tap a notification
  const handleActionPerformed = async (payload) => {
    const { notificationId, type, deviceId, plantId, click_action_android } = payload;
    let url;
    if (type == 'alarm' || type == 'isNotAlive') {
      url = click_action_android.split('//')[1];
    } else if (type == 'fwUpdateResult') {
      url = `${routes.plants}/${plantId}/${routes.device}/${deviceId}/${routes.softwareUpdate}`;
    } else {
      url = `${routes.notifications}`;
    }
    await NotificationsApi.readNotification(notificationId);
    navigate(url);
  };
};
