import { Outlet, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const ProtectedRoute = ({ variable, to }) => {
  if (!variable) {
    return <Navigate to={to} />;
  }
  return <Outlet />;
};
ProtectedRoute.propTypes = {
  variable: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  to: PropTypes.string,
};
export default ProtectedRoute;
