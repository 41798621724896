// Cognito
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import envConfig from '../env-config.json';

const poolData = {
  UserPoolId: envConfig.REACT_APP_USER_POOL_ID,
  ClientId: envConfig.REACT_APP_CLIENT_ID,
};

export default new CognitoUserPool(poolData);
