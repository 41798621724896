import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentStep: 0,
  isLoading: false,
  // User input
  isMisPresent: false,
  isPaused: false,
  deviceType: '',
  deviceModem: '',
  levelInputValue: 'detected value',
  level2ndInputValue: 'detected value',
  levelInputManuallyChanged: false,
  levelDetectionProgress: 0,
  levelInitialValue: '',
  level2ndInitialValue: '',
  progressLoading: false,
  skip2ndMeasurement: false,
};

const probeCalibrationSlice = createSlice({
  name: 'probeCalibration',
  initialState,
  reducers: {
    decreaseStep(state) {
      state.currentStep--;
    },
    increaseStep(state) {
      state.currentStep++;
    },
    resetState() {
      return { ...initialState };
    },
    setDeviceType(state, action) {
      state.deviceType = action.payload;
    },
    setDeviceModem(state, action) {
      state.deviceModem = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setIsMisPresent(state, action) {
      state.isMisPresent = action.payload;
    },
    setIsPaused(state, action) {
      state.isPaused = action.payload;
    },
    setLevelDetectionProgress(state, action) {
      state.levelDetectionProgress = action.payload;
    },
    setLevelInitialValue(state, action) {
      state.levelInitialValue = action.payload;
    },
    setLevel2ndInitialValue(state, action) {
      state.level2ndInitialValue = action.payload;
    },
    setLevelInputValue(state, action) {
      state.levelInputValue = action.payload;
    },
    setLevel2ndInputValue(state, action) {
      state.level2ndInputValue = action.payload;
    },
    setLevelInputManuallyChanged(state, action) {
      state.levelInputManuallyChanged = action.payload;
    },
    setProgressLoading(state, action) {
      state.progressLoading = action.payload;
    },
    setSkip2ndMeasurement(state, action) {
      state.skip2ndMeasurement = action.payload;
    },
    toggleIsPaused(state) {
      state.isPaused = !state.isPaused;
    },
    toggleSkip2ndMeasurement(state) {
      state.skip2ndMeasurement = !state.skip2ndMeasurement;
    },
    resetLevelInput(state) {
      (state.levelInputValue = initialState.levelInputValue),
        (state.level2ndInputValue = initialState.level2ndInputValue);
    },
  },
});

export const probeCalibrationActions = probeCalibrationSlice.actions;

export default probeCalibrationSlice;
