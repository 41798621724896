import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  automaticModeInput: {},
  automaticMaticModeInput: {},
  byMeasureModeInput: {},
  bandLimit: '',
  chemicalQualityModeInput: {},
  colorMode: false,
  constantModeInput: {},
  updateCurrentModelData: null,
  currentStep: 0,
  cyclicModeInput: {},
  defaultInput: 0,
  defaultSecondInput: 0,
  dosageType: 1,
  externalModeInput: {},
  frequency: 0,
  input: '',
  isLoading: false,
  isRepeatCycle: false,
  isPumpSetup: false,
  lightSetup: 1,
  manualModeInput: {},
  measure: null,
  quantityLinenVolumeModeInput: {},
  quantityWaterVolumeModeInput: {},
  selectedMeasure: null,
  selectedModality: null,
  selectedValue: null,
  timeSpeedModeInput: {},
  currentModality: null,
  externalMeasure: null,
  showPumpSetupClPhRx: false,
};

const pumpSetupSlice = createSlice({
  name: 'pumpSetup',
  initialState,
  reducers: {
    decreaseStep(state) {
      state.currentStep = state.currentStep - 1;
    },
    increaseStep(state) {
      state.currentStep = state.currentStep + 1;
    },
    resetState() {
      return { ...initialState };
    },
    revertDosageType(state) {
      state.dosageType = state.dosageType === 1 ? 0 : 1;
    },
    setStep(state, action) {
      state.currentStep = action.payload;
    },
    setAutomaticModeInput(state, action) {
      state.automaticModeInput = action.payload;
    },
    setAutomaticMaticModeInput(state, action) {
      state.automaticMaticModeInput = action.payload;
    },
    setManualModeInput(state, action) {
      state.manualModeInput = action.payload;
    },
    setScheduledModeInput(state, action) {
      state.scheduledModeInput = action.payload;
    },
    setBandLimit(state, action) {
      state.bandLimit = action.payload;
    },
    setByMeasureModeInput(state, action) {
      state.chemicalQualityModeInput = Number(action.payload);
    },
    setChemicalQualityModeInput(state, action) {
      state.chemicalQualityModeInput = action.payload;
    },
    setColorMode(state, action) {
      state.isLoading = action.payload;
    },
    setConstantModeInput(state, action) {
      state.constantModeInput = action.payload;
    },
    setUpdateCurrentModelData(state, action) {
      state.updateCurrentModelData = action.payload;
    },
    setIsPumpSetup(state, action) {
      state.isPumpSetup = action.payload;
    },
    setCyclicModeInput(state, action) {
      state.cyclicModeInput = action.payload;
    },
    setDefaultInput(state, action) {
      state.defaultInput = action.payload;
    },
    setExternalModeInput(state, action) {
      state.externalModeInput = action.payload;
    },
    setFrequency(state, action) {
      state.frequency = action.payload;
    },
    setInput(state, action) {
      state.input = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setLightSetup(state, action) {
      state.isLoading = action.payload;
    },
    setQuantityLinenVolumeModeInput(state, action) {
      state.quantityLinenVolumeModeInput = action.payload;
    },
    setQuantityWaterVolumeModeInput(state, action) {
      state.quantityWaterVolumeModeInput = action.payload;
    },
    setShowPumpSetupClPhRx(state, action) {
      state.showPumpSetupClPhRx = action.payload;
    },
    setTimeSpeedModeInput(state, action) {
      state.timeSpeedModeInput = action.payload;
    },
    setDefaultSecondInput(state, action) {
      state.defaultSecondInput = action.payload;
    },
    selectMeasure(state, action) {
      state.selectedMeasure = action.payload;
    },
    selectModality(state, action) {
      state.selectedModality = action.payload;
    },
    setMeasure(state, action) {
      state.measure = action.payload;
    },
    toggleIsRepeatCycle(state) {
      state.isRepeatCycle = !state.isRepeatCycle;
    },
    setIsRepeatCycle(state, action) {
      state.isRepeatCycle = action.payload;
    },
    selectValue(state, action) {
      state.selectedValue = action.payload;
    },
    setValues(state, action) {
      (state.frequency = action.payload.frequency),
        (state.defaultInput = action.payload.defaultInput),
        (state.defaultSecondInput = action.payload.frequency);
    },
    setCurrentModality(state, action) {
      state.currentModality = action.payload;
    },
    setExternalMeasure(state, action) {
      state.externalMeasure = action.payload;
    },
  },
});

export const pumpSetupActions = pumpSetupSlice.actions;

export default pumpSetupSlice;
