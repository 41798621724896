import { configureStore } from '@reduxjs/toolkit';

import appSlice from './app-slice';
import calendarSlice from './calendar-slice';
import channelSlice from 'components/devices/IwSense/store/channel-slice';
import chlorinatorSlice from 'components/devices/Salt/store/chlorinator-slice';
import deviceSettingsSlice from './device-settings/device-settings-slice';
import deviceSlice from './device/device-slice';
import linkedOrganization from './linked-organization/linked-organization-slice';
import loginSlice from './login-slice';
import maModeSlice from 'components/devices/MotorPump/operativeModes/store/maModeSlice';
import membersSlice from './plant-members/plant-members-slice';
import notificationSlice from './notifications/notification-slice';
import operativeModeMpSlice from 'components/devices/MotorPump/store/operative-modes-mp-slice';
import organizationMemberSlice from './organization-member/organization-member-slice';
import operativeModesSlice from 'components/common/OperativeModes/store/operative-modes-slice';
import organizationSlice from './organization/organization-slice';
import outputSlice from 'components/devices/IwSense/store/output-slice';
import paramChartSlice from './parameters-chart/parameters-chart-slice';
import parametersSlice from '../components/parameters/Parameters/store/param-slice';
import peopleHomepageSlice from './people-homepage/people-homepage-slice';
import plantsSlice from './plant/plants-slice';
import probeCalibrationSlice from './probe-calibration-slice';
import pumpCalibrationSlice from './pump-calibration/pump-calibration-slice';
import pumpPressureSlice from 'components/devices/MotorPump/store/pump-pressure-slice';
import pumpPrimingSlice from './pump-priming-slice';
import pumpSetupSlice from './pump-setup-slice';
import scheduledModeSlice from './sheduled-mode-slice';
import selectNewOwnerSlice from './slect-new-owner/select-new-owner-slice';
import senseSettingsSlice from 'components/devices/IwSense/store/settings-slice';
import softwareUpdateSlice from './software-update/software-update-slice';
import startUpSlice from './device-start-up/device-startup-slice';
import timerCounterSlice from './timer-counter-modal/timer-counter-modal-slice';
import userSlice from './user-slice';
import wifiConnection from './wifiConnection/wifi-connection-slice';
import pulseModeSlice from 'components/devices/MotorPump/operativeModes/store/pulseModeSlice';

const store = configureStore({
  reducer: {
    app: appSlice.reducer,
    calendar: calendarSlice.reducer,
    channel: channelSlice.reducer,
    chlorinator: chlorinatorSlice.reducer,
    device: deviceSlice.reducer,
    deviceSettings: deviceSettingsSlice.reducer,
    login: loginSlice.reducer,
    linkedOrganization: linkedOrganization.reducer,
    maMode: maModeSlice.reducer,
    members: membersSlice.reducer,
    newOwner: selectNewOwnerSlice.reducer,
    notifications: notificationSlice.reducer,
    operativeModesMp: operativeModeMpSlice.reducer,
    organization: organizationSlice.reducer,
    organizationMember: organizationMemberSlice.reducer,
    operativeModes: operativeModesSlice.reducer,
    output: outputSlice.reducer,
    paramChart: paramChartSlice.reducer,
    parameters: parametersSlice.reducer,
    peopleHomepage: peopleHomepageSlice.reducer,
    plants: plantsSlice.reducer,
    probeCalibration: probeCalibrationSlice.reducer,
    pumpCalibration: pumpCalibrationSlice.reducer,
    pumpPressure: pumpPressureSlice.reducer,
    pumpPriming: pumpPrimingSlice.reducer,
    pumpSetup: pumpSetupSlice.reducer,
    pulseMode: pulseModeSlice.reducer,
    scheduledMode: scheduledModeSlice.reducer,
    senseSettings: senseSettingsSlice.reducer,
    startUp: startUpSlice.reducer,
    softwareUpdate: softwareUpdateSlice.reducer,
    timerCounter: timerCounterSlice.reducer,
    user: userSlice.reducer,
    wifiConnection: wifiConnection.reducer,
  },
});

export default store;
