import { createSlice } from '@reduxjs/toolkit';

const organizationSlice = createSlice({
  name: 'organization',
  initialState: {
    adminLeavingOrg: false,
    attributes: [],
    organizationData: {
      id: null,
      creatorId: null,
      email: undefined,
      invitations: [],
      members: [],
      name: undefined,
      type: [],
      vatNumber: null,
    },
  },
  reducers: {
    setOrganizationData(state, action) {
      state.organizationData = action.payload;
    },
    setAttributes(state, action) {
      state.attributes = action.payload;
    },
    setAdminLeavingOrg(state, action) {
      state.adminLeavingOrg = action.payload;
    },
  },
});

export const organizationActions = organizationSlice.actions;

export default organizationSlice;
