import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeCycle: '',
  activeCycle1: '',
  activeCycle2: '',
  activeCycle3: '',
  activeCycle4: '',
  activeCycle5: '',
  activeCycle6: '',
  activeCycle7: '',
  activeCycle8: '',
  activeSelectedAlarms: [],
  activeTimeCycles: false,
  alarms: [],
};
const scheduledModeSlice = createSlice({
  name: 'scheduledMode',
  initialState,
  reducers: {
    resetState() {
      return { ...initialState };
    },
    setActiveCycle1(state, action) {
      state.activeCycle1 = action.payload;
    },
    setActiveCycle2(state, action) {
      state.activeCycle2 = action.payload;
    },
    setActiveCycle3(state, action) {
      state.activeCycle3 = action.payload;
    },
    setActiveCycle4(state, action) {
      state.activeCycle4 = action.payload;
    },
    setActiveCycle5(state, action) {
      state.activeCycle5 = action.payload;
    },
    setActiveCycle6(state, action) {
      state.activeCycle6 = action.payload;
    },
    setActiveCycle7(state, action) {
      state.activeCycle7 = action.payload;
    },
    setActiveCycle8(state, action) {
      state.activeCycle8 = action.payload;
    },
    setActiveCycleAction(state, action) {
      state.activeCycle = action.payload;
    },
    setActiveSelectedAlarms(state, action) {
      state.activeSelectedAlarms = action.payload;
    },
    setActiveTimeCycles(state, action) {
      state.activeTimeCycles = action.payload;
    },
    setAlarms(state, action) {
      state.alarms = action.payload;
    },
  },
});

export const scheduledModeActions = scheduledModeSlice.actions;

export default scheduledModeSlice;
