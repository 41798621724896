import React from 'react';
import PropTypes from 'prop-types';

// Components
import BaseModal from 'components/base/BaseModal/BaseModal';
import BaseButton from '../BaseButton/BaseButton';
import BaseImage from '../BaseImage/BaseImage';

// Hookes
import { useParagraphsMarkup } from 'hooks/i18-paragraphs-hook';

// Styles
import styles from './OptionModal.module.scss';

// Translations
import { useTranslation } from 'react-i18next';

/**
 * Component used to display the Delete modal
 * @prop {boolean} isOpen
 * @prop {function} closeModal
 */
const OptionModal = ({
  btnFn,
  btnText,
  buttonTextClass,
  buttonType,
  closeModal,
  customClass,
  img,
  isCancelBtn,
  isCancelBlue,
  isOpen,
  nrOfParagraphs,
  subtitle,
  title,
}) => {
  const { t } = useTranslation();

  return (
    <BaseModal
      isOpen={isOpen}
      closeFn={closeModal}
      customClass={`${styles.optionModal} ${customClass}`}
    >
      <h2 className={`${!subtitle && styles.optionModal__padding} ${styles.optionModall__title}`}>
        {title}
      </h2>
      {subtitle && nrOfParagraphs ? (
        useParagraphsMarkup(nrOfParagraphs, subtitle, styles.optionModal__subtitle)
      ) : subtitle ? (
        <h2 className={styles.optionModal__subtitle}>{t(subtitle)}</h2>
      ) : null}
      {img && <BaseImage className={styles.optionModal__img} img={img}></BaseImage>}
      <BaseButton onClick={btnFn} buttonType={buttonType}>
        {btnText}
      </BaseButton>
      {!isCancelBtn && (
        <BaseButton
          customClass={`${styles.optionModal__text} ${
            isCancelBlue ? styles.optionModal__cancelBlue : null
          } ${buttonTextClass}`}
          onClick={closeModal}
          buttonType='text'
        >
          {'Cancel'}
        </BaseButton>
      )}
    </BaseModal>
  );
};
OptionModal.propTypes = {
  btnFn: PropTypes.func,
  btnText: PropTypes.string,
  buttonType: PropTypes.string,
  buttonTextClass: PropTypes.string,
  closeModal: PropTypes.func,
  customClass: PropTypes.string,
  img: PropTypes.string,
  isCancelBlue: PropTypes.bool,
  isCancelBtn: PropTypes.bool,
  isOpen: PropTypes.bool,
  nrOfParagraphs: PropTypes.number,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};
export default OptionModal;
