import { createSlice } from '@reduxjs/toolkit';

const peopleHomepageSlice = createSlice({
  name: 'peoplehomepage',
  initialState: {
    attr: null,
    otherAttr: null,
    input: null,
    newMemberEmail: null,
    email: null,
    orgName: null,
    orgNumber: null,
  },
  reducers: {
    setAttr(state, action) {
      state.attr = action.payload;
    },
    setOrgEmail(state, action) {
      state.email = action.payload;
    },
    setOrgName(state, action) {
      state.orgName = action.payload;
    },
    setOrgNumber(state, action) {
      state.orgNumber = action.payload;
    },
    setOtherAttr(state, action) {
      state.otherAttr = action.payload;
    },
    setInput(state, action) {
      state.input = action.payload;
    },
    setOrgData(state, action) {
      state.orgName = action.payload.name;
      state.attr = action.payload.attr;
      state.email = action.payload.email;
      state.otherAttr = action.payload.otherAttr;
      state.orgNumber = action.payload.number;
    },
    setNewMemberEmail(state, action) {
      state.newMemberEmail = action.payload;
    },
  },
});

export const peopleHomepageActions = peopleHomepageSlice.actions;

export default peopleHomepageSlice;
